import HeaderCom from '../../../components/M-header.vue';

export default {
  name: 'adminRecord',
  components: {
    HeaderCom
  },
  data() {
    return {
      total: 0, // 总条目数
      currentPage: 1, // 当前页
      limit: 10, // 每页显示数量
      loading: false,
      formInline: {},
      auditArray: [],
      degreeList: JSON.parse(sessionStorage.getItem('degreeList')),
      specialList: JSON.parse(sessionStorage.getItem('specialList')),
      levelList: JSON.parse(sessionStorage.getItem('levelList')),
      jobList: JSON.parse(sessionStorage.getItem('jobList')),
      titleList: JSON.parse(sessionStorage.getItem('titleList')),
      stateList: [{ value: '100', label: '待审核' }, { value: '150', label: '审核不通过' }, { value: '200', label: '审核通过' }],
      tabColumns: [
        { title: '序号', type: 'index', width: 50, align: 'center' },
        { title: '姓名', key: 'name', align: 'center', width: 80 },
        { title: '性别', key: 'gender', align: 'center', width: 70 },
        { title: '身份证号', key: 'idNumber', align: 'center', tooltip: true, resizable: true, width: 130 },
        { title: '联系电话', key: 'tel', align: 'center', width: 110 },
        { title: '最高学历', key: 'highestDegree', align: 'center', width: 120 },
        { title: '测试专业名称', key: 'examMajor', align: 'center', tooltip: true, resizable: true, width: 130 },
        { title: '测试级别', key: 'examMajorTitle', align: 'center', width: 100 },
        { title: '工作单位', key: 'unitName', align: 'center', minWidth: 130, tooltip: true, resizable: true },
        { title: '现任专业职务', key: 'majorJob', align: 'center', tooltip: true, width: 120 },
        { title: '现任专业职务级别', key: 'majorJobTitle', align: 'center', tooltip: true, resizable: true, width: 140 },
        {
          title: '审核状态', key: 'currentStatus', align: 'center', width: 120, tooltip: true,
          render: (h, params) => {
            let tmpStr = "";
            if (params.row.currentStatus == 0) {
              tmpStr = "未提交";
            } else if (params.row.currentStatus == 100) {
              tmpStr = "待审核"; // #2d8cf0
            } else if (params.row.currentStatus == 150) {
              tmpStr = "审核不通过"; // #ed3f14
            } else {
              tmpStr = "审核通过"; // #19be6b
            }
            return h('span', {
              style: {
                color: (params.row.currentStatus == 100) ? "#2d8cf0" : (params.row.currentStatus == 150 ? "#ed3f14" : (params.row.currentStatus == 200 ? "#19be6b" : "#ff9900"))
              }
            }, tmpStr)
          }
        },
        { title: '审核人', key: 'currentStatusOperator', align: 'center', tooltip: true, resizable: true, width: 140,
          render: (h, params) => {
            let tmpStr = "";
            if (params.row.currentStatus == 0||params.row.currentStatus == 100) {
              tmpStr = "";
            } else {
              tmpStr=params.row.currentStatusOperator;
            }
            return h('span',  tmpStr);
          }
        },
        { title: '审核时间', key: 'currentStatusOperatDate', align: 'center', tooltip: true, resizable: true, width: 160 },
        { title: '审核意见', key: 'currentStatusComment', align: 'center', tooltip: true, resizable: true, minWidth: 140 }
      ],
      tabData: [],
      examTime: {
        shortcuts: [
          {
            text: '最近一周',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            }
          },
          {
            text: '最近一个月',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            }
          },
          {
            text: '最近三个月',
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            }
          }
        ]
      },
      batchList: [],//批次
    }
  },
  mounted() {
    // this.getData();
    this.getBatchOptions();
  },
  methods: {
    //批次list  同步方法必须先执行他
    getBatchOptions() {
      this.$manageHttp.getBatchOptions().then(data => {
        if (data.code == '200') {
          this.batchList = data.data;
          this.formInline.batchId=this.batchList[0].id;
          this.getData();
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {
        this.$Message.error(error);
      })
    },

    // 页码改变的回调，返回改变后的页码(当前页)
    sizeChange(val) {
      this.currentPage = val;
      this.getData();
    },
    // 切换每页条数时的回调，返回切换后的每页条数(每页数)
    pageChange(val) {
      this.limit = val;
      this.getData();
    },
    onSearch() {
      this.currentPage = 1;
      this.getData();
    },
    auditChange(val) {
      this.auditArray[0] = val[0];
      this.auditArray[1] = val[1];
    },
    getData() {
      this.loading = true;
      var params = {
        pageNum: this.currentPage,
        pageSize: this.limit,
        orderByColumn: 'b.createTime',
        isAsc: 'desc',
        name: this.formInline.name,
        idNumber: this.formInline.idNumber,
        tel: this.formInline.tel,
        unitName: this.formInline.unitName,
        highestDegree: this.formInline.highestDegree,
        examMajor: this.formInline.examMajor,
        examMajorTitle: this.formInline.examMajorTitle,
        majorJob: this.formInline.majorJob,
        majorJobTitle: this.formInline.majorJobTitle,
        currentStatus: this.formInline.currentStatus,
        beginWorkFlowDate: this.auditArray[0],
        endWorkFlowDate: this.auditArray[1],
        batchId: this.formInline.batchId
      }
      this.$manageHttp.workFlowlist(params).then(data => {
        if (data.code == '200') {
          this.loading = false;
          this.tabData = data.rows;
          this.total = data.total;
        } else {
          this.$Message.error(data.msg);
        }
      }).catch(error => {
        // this.$Message.error(error);
      })
    }
  }
}